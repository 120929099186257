import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Header from './components/Header';
import SideNav from './components/SideNav';
import MobileNav from './components/MobileNav';
import FooterBlock from './components/Footer-block';
import Footer from './components/Footer';
//import SearchForm from './components/SearchForm';
import Post from './components/Post';
import About from './components/About';
import Contact from './components/Contact';
import PostDetail from './components/Post_Detail';
import Home from './components/Home';




function App() {
  const [navOpen, setNavOpen] = useState(false);
  console.log("navOpen in App:", navOpen);
  return (
    <Router>
    <div className="App " key={navOpen.toString()}>
      <section className="d-flex">
      <SideNav key={navOpen.toString()} navOpen={navOpen} />
        <div className="main-content">
        <Header />

        <MobileNav navOpen={navOpen} setNavOpen={setNavOpen} />
        <main className='container pt-4 mt-5'>
          <Routes>
              <Route path="/about" element={<About />} />
              <Route path="" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/Project" element={<Post />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/PostDetail" element={<PostDetail />} />
              <Route path="/project/:postId" element={<PostDetail />} />
          </Routes>
        </main>
        <Footer />


      </div>
      
      </section>
    </div>
    </Router>
  );
}

export default App;

