// src/components/PostList.js

import React from 'react';
import posts from '../data/posts';
import { Link } from 'react-router-dom';
import arrowRight from '../images/arrow-right.png';

function PostList() {
  return (
    <div>
      {posts.map(post => (
            <div className="card post-item bg-transparent border-0 mb-5" key={post.id}>
                <Link to={`/project/${post.id}`} > 
                  <img className="card-img-top rounded-0" src={post.background} alt="" />
                  </Link>
                <div className="card-body px-0">
                  <h2 className="">
                    <Link className="card-title text-white opacity-75-onHover" to={`/project/${post.id}`}>
                     {post.title}
                    </Link>
                  </h2>
                  <ul className="post-meta mt-3">
                    <li className="d-inline-block mr-3">
                      <span className="fas fa-clock text-primary"></span>
                      <a className="ml-1">{post.time}</a>
                    </li>
                    <li className="d-inline-block">
                      <span className="fas fa-list-alt text-primary"></span>
                      <a className="ml-1">{post.cat}</a>
                    </li>
                  </ul>
                  <p className="card-text my-4">
                    {post.content}
                  </p>
                  <Link to={`/project/${post.id}`} className="btn btn-primary">Read More <img src={arrowRight} alt="" /> </Link>
                </div>
              </div>
      ))}
    </div>
  );
}

export default PostList;


