// About.js
import React from 'react';
import personal_lg from '../images/personal2.jpg';
import arrowRight from '../images/arrow-right.png';

function About() {
    return (
        <div className="container py-4 my-5">
            <div className="row">
                <div className="col-md-9">
                    <img className="img-fluid" src={personal_lg} alt=""/>
                    <h1 className="text-white add-letter-space my-4">Hi,I’m Keng Zhan (Ken), a full stack developer and UI/UX designer</h1>
                    <p>With a solid IT background and a passion for design, I've carved a niche for myself in the dynamic world of web development and UI/UX design. My journey in Melbourne has blessed me with invaluable local work experience, allowing me to understand and adapt to diverse work cultures seamlessly. I take pride in my ability to think creatively and learn continuously. Holding a Master's from Monash University and bachelor's from RMIT.</p>
                    <h2 className="text-white add-letter-space my-5">I'm not just about codes and designs; I'm about bringing visions to life.</h2>
                


                    <ul className="list-unstyled">
                        <li className="bullet-list-item mb-4">
                            <h3 className="text-white mb-3 add-letter-space">Education</h3>
                            <div className='row'>
                            <h5 className="text-white col-lg-7 col-md-7 col-sm-7">Monash university, Melbourne </h5> 
                            <h5 className="item-time text-white col-lg-5 col-md-5 col-sm-5">Mar 2022 -  now</h5>
                            <h6 className="text-white">Master of Design </h6>
                            <p>Key Area: • interaction Design, User Research, 3d modeling</p>
                            </div>
                            <br></br>
                            <div className='row'>
                            <h5 className="text-white col-lg-6 col-md-7 col-sm-7">ECA, Melbourne</h5> 
                            <h5 className="item-time text-white col-lg-6 col-md-5 col-sm-5">Sep 2018 - Sep 2019</h5>
                            <h6 className="text-white">ICT Professional Year</h6>
                            <p>The program aim to develop Australian workplace readiness in graduates</p>
                            </div>
                            <br></br>

                            <div className='row'>
                            <h5 className="text-white col-lg-6 col-md-7 col-sm-7">RMIT, Melbourne</h5> 
                            <h5 className="item-time text-white col-lg-6 col-md-5 col-sm-5">June 2015 - Feb 2018</h5>
                            <h6 className="text-white">Bachelor of Information Technology </h6>
                            <p className="text-white">Key Area:</p>
                            <p >• Web Design/Development with C#, .NET,  Front-end(HTML,CSS,JavaScript)</p>
                            <p >• UI Design</p>
                            <p >• Mobile App development with Swift, Xcode</p>
                            </div>
                        </li>
                        <li className="bullet-list-item mb-4">
                            <h3 className="text-white mb-3 add-letter-space">Key Skills</h3>
                            <div className='row'>
                                <div className="col-lg-6 col-md-6">

<h6 className="text-white">Technical skills:</h6>
                                <p>•Full stack develop •software testing •User experience Design • User interface design •3d modeling •AR building</p>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                 <h6 className="text-white">Non-technical skills:</h6>
                                 <p>• Agile • Problem-solving • Teamwork • Communication </p>
                                </div>
                            </div>
                        </li>
                        <li className="bullet-list-item mb-4">
                            <h3 className="text-white mb-3 add-letter-space">Employment​ ​History</h3>
                            <div className='row'>
                            <h5 className="text-white col-lg-7 col-md-7 col-sm-7">Ringo Space Art  Hangzhou</h5> 
                            <h5 className="item-time text-white col-lg-5 col-md-5 col-sm-5">Apr 2021 - Dec 2021</h5>
                            <h6 >Operator, Developer</h6>

                            </div>
                            <br></br>
                            <div className='row'>
                            <h5 className="text-white col-lg-6 col-md-7 col-sm-7">Coring technology PTY LTD  Melbourne </h5> 
                            <h5 className="item-time text-white col-lg-6 col-md-5 col-sm-5">Oct 2019 - Oct 2020</h5>
                            <h6 >Full Stack Developer</h6>
                            </div>
                            <br></br>

                            <div className='row'>
                            <h5 className="text-white col-lg-6 col-md-7 col-sm-7">GUGU PTY LTD  Melbourne</h5> 
                            <h5 className="item-time text-white col-lg-6 col-md-5 col-sm-5">Feb 2019 - Sep 2020</h5>
                            <h6>Entrepreneurial project, development/design</h6>
                            </div>
                            <br></br>
                            <div className='row'>
                            <h5 className="text-white col-lg-6 col-md-7 col-sm-7">Metric Education Melbourne</h5> 
                            <h5 className="item-time text-white col-lg-6 col-md-5 col-sm-5">June 2019 - Oct 2019</h5>
                            <h6 >Web Developer</h6>
                            </div>
                        </li>
                        {/* <li className="bullet-list-item">
                            <h3 className="text-white mb-3 add-letter-space">You should get  working on day one</h3>
                            <ol className="pl-0">
                                <li className="mb-2">Sll the Themefisher items are designed to be with the latest , We check all.</li>
                                <li className="mb-2">Comments that threaten or harm the reputation of any person or organization</li>
                                <li className="mb-2">Personal information including, but  limited to, email addresses, telephone numbers</li>
                                <li>Any Update come in The technology  Customer will get automatic  Notification.</li>
                            </ol>
                        </li> */}
                    </ul>
                    <br></br>
                    <div className="widget text-center">
                            <h3 className="widget-title text-white d-inline-block mb-4">Resume Download</h3>
                            <div className="d-block">
                                <a className="btn btn-primary" href="/public/Ken_Zhan_resume.pdf" download>Download <img src={arrowRight} alt=""/></a>
                    </div>
                    </div>


                            

                </div>
            </div>
        </div>
    );
}

export default About;
