import React from 'react';
import PostList from './PostList';



function Post({ post }) {
  return (
    <div className="row justify-content-between">
      <div className="col-lg">
         <PostList />
      </div>
    </div>
  );
}

export default Post;
