import React from 'react';
import logo from '../images/logo_lg.png';


function MobileNav({navOpen, setNavOpen }) {



  const toggleNav = () => {
    setNavOpen(prevState => !prevState);
  };
  const closeNav = () => {
    setNavOpen(false);
  };



  return (
    <div className="mobile-nav-cont">
      <header className="mobile-nav pt-4">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6">
                <img src={logo} alt="" />
            </div>
            <div className="col-6 text-end">
            <button  className="nav-toggle bg-transparent border text-white" onClick={toggleNav}>
              <span className={`fa ${navOpen ? 'fa-times' : 'fa-bars'}`}></span>
            </button>
            </div>
          </div>
        </div>
      </header>
      <div className={`nav-toggle-overlay ${navOpen ? 'show' : ''}`} onClick={closeNav}></div>
    </div>
  );
}

export default MobileNav;
