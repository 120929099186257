import React from 'react';

function Header() {
  return (
    <header>
      {/* ... header内容 ... */}
    </header>
  );
}

export default Header;