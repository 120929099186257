import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/logo_m.png';

function SideNav({ navOpen }) {
  useEffect(() => {
    console.log("navOpen in SideNav useEffect:", navOpen);
  }, [navOpen]);

  return (
    <aside>
      <div className={` position-sticky d-flex flex-column justify-content-between sidenav ${navOpen ? 'show' : ''} `}>
        <Link to="/home" className="logo">
          <img src={logo} alt="/home" />
        </Link>

        <div className="navbar navbar-dark my-4 p-0 font-primary">
          <ul className="navbar-nav w-100">
            <li className="nav-item @@home">
             <Link className="nav-link text-white px-0" to="/home">Home</Link>
            </li>
            <li className="nav-item @@about">
                <Link className="nav-link text-white px-0" to="/about">About</Link>
            </li>
            <li className="nav-item @@contact">
              <Link className="nav-link text-white px-0" to="/project">Project</Link>
            </li>
            <li className="nav-item @@contact">
              <Link className="nav-link text-white px-0" to="/contact">Contact</Link>
            </li>
          </ul>
        </div>

        <ul className="list-inline nml-2">
                            <li className="list-inline-item">
                                <a href="https://www.instagram.com/zkkkken/?hl=en" className="text-white text-primary-onHover p-2">
                                    <span className="fab fa-instagram"></span>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="http://www.linkedin.com/in/zkkkkforwork/" className="text-white text-primary-onHover p-2">
                                    <span className="fab fa-linkedin-in"></span>
                                </a>
                            </li>
        </ul>
      </div>
    </aside>
  );
}

export default SideNav;

