import React from 'react';
import PostList from './PostList';
import personal_sm from '../images/personal2-sm.jpg';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div className="row justify-content-between">
      <div className="col-lg-7">
        <PostList />
        {/* end of post-item */}
      </div>
      <div className="col-lg-4 col-md-5">
      <div className="widget text-center">
                        <Link className="d-block mx-auto" to="/about">
                        <img className="author-thumb-sm rounded-circle" src={personal_sm} alt=""/>
                        </Link>
                        <Link className="h2 widget-title text-white d-inline-block mt-4">About Me</Link>
                        <p className="mt-4">Full stack developer and UI/UX designer with Agile experience, blending IT expertise with creative design insights. Skilled in creative thinking and self-learning.</p>
                        <ul className="list-inline mt-3">

                            <li className="list-inline-item">
                                <a href="https://www.instagram.com/zkkkken/?hl=en" className="text-white text-primary-onHover p-2">
                                    <span className="fab fa-instagram"></span>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a href="http://www.linkedin.com/in/zkkkkforwork/" className="text-white text-primary-onHover p-2">
                                    <span className="fab fa-linkedin-in"></span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* <div className="widget bg-dark p-4 text-center">
                        <h2 className="widget-title text-white d-inline-block mt-4">Subscribe Blog</h2>
                        <p className="mt-4">Lorem ipsum dolor sit coectetur elit. Tincidu nfywjt leo mi, urna. Arcu ve isus, condimentum ut vulpate cursus por.</p>
                        <form action="#">
                            <div className="form-group">
                                <input type="email" className="form-control bg-transparent rounded-0 my-4" placeholder="Your Email Address" />
                                <button className="btn btn-primary">Subscribe Now <img src="images/arrow-right.png" alt=""/></button>
                            </div>
                        </form>
                    </div> */}

        {/* <div className="card post-item bg-transparent border-0 mb-5">
          <a href="post-details.html">
            <img className="card-img-top rounded-0" src="images/post/post-md/01.png" alt="" />
          </a>
          <div className="card-body px-0">
            <h2 className="card-title">
              <a className="text-white opacity-75-onHover" href="post-details.html">
                Occaecat ut elit voluptate on nisi est nisi euro occaecat
              </a>
            </h2>
            <ul className="post-meta mt-3 mb-4">
              <li className="d-inline-block mr-3">
                <span className="fas fa-clock text-primary"></span>
                <a className="ml-1" href="#">24 April, 2016</a>
              </li>
              <li className="d-inline-block">
                <span className="fas fa-list-alt text-primary"></span>
                <a className="ml-1" href="#">Photography</a>
              </li>
            </ul>
            <a href="post-details.html" className="btn btn-primary">Read More <img src="images/arrow-right.png" alt="" /></a>
          </div>
        </div> */}
        {/* end of widget-post-item */}
      </div>
    </div>
  );
}

export default Home;
