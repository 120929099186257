// Contact.js
import React, { useState } from 'react';

function Contact() {

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // 在这里，您可以处理表单数据，例如发送到服务器。
        // 但是，由于我们只是显示一个消息，所以我们将设置isSubmitted为true。
        setIsSubmitted(true);
    };


    return (
        <div class="row">
        <div class="col-md-10">
            <div class="contact-form bg-dark">
                <h1 class="text-white add-letter-space mb-5">Lets Contact Us</h1>
                <form onSubmit={handleSubmit} class="needs-validation" novalidate>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group mb-5">
                                <label for="firstName" class="text-black-300">Your First Name</label>
                                <input type="text" id="firstName" class="form-control bg-transparent rounded-0 border-bottom shadow-none pb-15 px-0"  required />
                                <p class="invalid-feedback">Your first-name is required!</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-5">
                                <label for="lastName" class="text-black-300">Your Last Name</label>
                                <input type="text" id="lastName" class="form-control bg-transparent rounded-0 border-bottom shadow-none pb-15 px-0"  required />
                                <p class="invalid-feedback">Your last-name is required!</p>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group mb-5">
                                <label for="email" class="text-black-300">E-Mail Address</label>
                                <input type="email" id="email" class="form-control bg-transparent rounded-0 border-bottom shadow-none pb-15 px-0" required />
                                <p class="invalid-feedback">Your email is required!</p>
                            </div>
                        </div>
                        
                        <div class="col-md-12">
                            <div class="form-group mb-5">
                                <label for="message" class="text-black-300">Your message</label>
                                <textarea name="message" class="form-control bg-transparent rounded-0 border-bottom shadow-none pb-15 px-0" required></textarea>
                                <p class="invalid-feedback">Message is required!</p>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-sm btn-primary">Send Now <img src="images/arrow-right.png" alt=""/></button>
                        </div>
                    </div>
                </form>
                {isSubmitted && <div className="mt-3 text-success">Your message has been sent successfully!</div>}
            </div>
        </div>
    </div>
    );
}

export default Contact;