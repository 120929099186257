import React from 'react';

function Footer() {
  return (
<footer className="bg-dark">
      {
    <div className="container">
        <div className="row text-center">
            <div className="mb-5">
                <h5 className="font-primary text-white mb-4"> &copy; {new Date().getFullYear()} KENG ZHAN. All rights reserved.</h5>
            </div>
          


        </div>
    </div>


     }
    </footer>
  );
}

export default Footer;